import React from "react";
import Row from "react-bootstrap/Row";
import { Outlet } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components";

function App() {
  return (
    <div className="container-fluid p-0 h-100 d-flex flex-column">
      <Row className="flex-shrink-0">
        <NavBar />
      </Row>
      <Row className="flex-fill" style={{ overflowY: "scroll" }}>
        <Outlet />
      </Row>
    </div>
  );
}

export default App;
