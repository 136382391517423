import React from "react";
import { FileIndex } from "../types";
import ListGroup from "react-bootstrap/ListGroup";

const FileTree: React.FC<{
  fileList: FileIndex[];
  selectedItem: FileIndex | undefined;
  setSelectedItem: (item: FileIndex | undefined) => void;
}> = ({ fileList, selectedItem, setSelectedItem }) => {
  return fileList.length === 0 ? (
    <div>No files</div>
  ) : (
    <ListGroup variant="flush" style={{ overflowY: "scroll" }}>
      {fileList.map((item) => (
        <ListGroup.Item
          key={item.file_name}
          active={item === selectedItem}
          onClick={() => setSelectedItem(item)}
        >
          {item.file_name}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};
export default FileTree;
