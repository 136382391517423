import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  Row,
  Col,
  Stack,
  Button,
  Form,
  Container,
  Offcanvas,
  Navbar,
  ToggleButton,
  Dropdown,
} from "react-bootstrap";
import { UploadButton, RenameModal, FileTree } from "../components";
import { FileIndex } from "../types";
import { filesService } from "../services";
import ConfirmDeleteModal from "../components/confirmDeleteModal";

function FilesPage() {
  const [selectedItem, setSelectedItem] = useState<FileIndex>();
  const [fileContent, setFileContent] = useState<string>("");
  const [fileUrl, setFileUrl] = useState<string>();
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<FileIndex[]>([]);
  const [filteredList, setFilteredList] = useState<FileIndex[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [typeFilter, setTypeFilter] = useState<string>("all");
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const [autoplay, setAutoplay] = useState<boolean>(false);

  const refreshFileList = async () => {
    const files = await filesService.listFiles();
    setFileList(files);
    // if (files.length > 0) {
    //   setSelectedItem(files[0]);
    // }
  };

  const selectItem = (item: FileIndex | undefined) => {
    setSelectedItem(item);
    setShowMobileNav(false);
  };

  const autoselectNext = () => {
    if (selectItem !== undefined && filteredList.length > 0) {
      const newItem = filteredList[filteredList.indexOf(selectedItem!) + 1];
      setSelectedItem(newItem);
    }
  };

  useEffect(() => {
    async function getFile() {
      if (selectedItem) {
        if (["video", "image"].indexOf(selectedItem.file_type) >= 0) {
          setFileUrl(await filesService.getFileUrl(selectedItem.file_name));
          setFileContent("");
        } else if (selectedItem.file_type === "text") {
          setFileUrl("");
          setFileContent(await filesService.getFile(selectedItem.file_name));
        } else {
          console.log("unhandled", selectedItem);
        }
      }
    }
    getFile();
  }, [selectedItem]);

  useEffect(() => {
    refreshFileList();
  }, []);

  useEffect(() => {
    var filteredFiles = fileList;
    if (typeFilter !== "all") {
      filteredFiles = filteredFiles.filter((x) => x.file_type === typeFilter);
    }
    if (searchText === "") {
      setFilteredList(filteredFiles);
    } else {
      setFilteredList(
        filteredFiles.filter(
          (x) =>
            x.file_name.toUpperCase().indexOf(searchText.toUpperCase()) > -1
        )
      );
    }
  }, [searchText, fileList, typeFilter]);

  return (
    <>
      <RenameModal
        showModal={showRenameModal}
        setShowModal={setShowRenameModal}
        currentFileName={selectedItem?.file_name as string}
        refreshFileList={refreshFileList}
      />
      <ConfirmDeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        currentFileName={selectedItem?.file_name as string}
        refreshFileList={refreshFileList}
      />
      <Container fluid className="h-100">
        <Row xs="1" className="flex-shrink-0">
          <Navbar expand="sm" expanded={showMobileNav}>
            <Container>
              <Navbar.Brand>{selectedItem?.file_name}</Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-sm`}
                onClick={() => setShowMobileNav(!showMobileNav)}
              />
              <Navbar.Offcanvas placement="end">
                <Offcanvas.Header closeButton>
                  <Form.Control
                    placeholder="Search"
                    style={{ width: "200px" }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Dropdown>
                    <Dropdown.Toggle>{typeFilter}</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setTypeFilter("all")}>
                        all
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setTypeFilter("video")}>
                        video
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setTypeFilter("image")}>
                        image
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setTypeFilter("text")}>
                        text
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                  <Container fluid>
                    <Row>
                      <Stack direction="horizontal" gap={2}>
                        <span className="ms-auto" />
                        <ToggleButton
                          type="checkbox"
                          checked={autoplay}
                          value="1"
                          variant="outline-primary"
                          onClick={() => setAutoplay(!autoplay)}
                        >
                          Autoplay
                        </ToggleButton>
                        <UploadButton refreshFileList={refreshFileList} />
                        <Button onClick={() => setShowRenameModal(true)}>
                          Rename
                        </Button>
                        <Button onClick={() => setShowDeleteModal(true)}>
                          Delete
                        </Button>
                      </Stack>
                    </Row>
                    <Row className="d-inline d-md-none w-100 m-0">
                      <div className="pt-2 px-0">
                        <FileTree
                          fileList={filteredList}
                          selectedItem={selectedItem}
                          setSelectedItem={selectItem}
                        />
                      </div>
                    </Row>
                  </Container>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </Row>
        <Row className="flex-fill h-100" style={{ overflowY: "scroll" }}>
          <Col
            lg="3"
            className="h-100 d-none d-md-inline"
            style={{ overflowY: "scroll" }}
          >
            <Row>
              <Col>
                <Form.Control
                  placeholder="Search"
                  className="m-1 p-1"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Col>
              <Col lg="4">
                <Dropdown>
                  <Dropdown.Toggle>{typeFilter}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setTypeFilter("all")}>
                      all
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setTypeFilter("video")}>
                      video
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setTypeFilter("image")}>
                      image
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setTypeFilter("text")}>
                      text
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <FileTree
              fileList={filteredList}
              selectedItem={selectedItem}
              setSelectedItem={selectItem}
            />
          </Col>
          <Col className="h-100" xs={true}>
            <Container className="mh-100 mw-100">
              <Row>
                <Col style={{ maxHeight: "80vh" }}>
                  {selectedItem === undefined ? (
                    <div>Nothing selected</div>
                  ) : selectedItem.file_type === "image" ? (
                    <img
                      alt="Fetching..."
                      src={fileUrl}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "scale-down",
                      }}
                    />
                  ) : selectedItem.file_type === "video" ? (
                    <ReactPlayer
                      url={fileUrl}
                      controls
                      playing={autoplay}
                      onEnded={autoselectNext}
                    />
                  ) : selectedItem.file_type === "text" ? (
                    <div className="h-100" style={{ overflowY: "scroll" }}>
                      <h3>{selectedItem.file_name}</h3>
                      <body
                        style={{
                          whiteSpace: "pre-line",
                          paddingBottom: "50px",
                        }}
                      >
                        {fileContent}
                      </body>
                    </div>
                  ) : (
                    <div>{selectedItem.file_name}</div>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FilesPage;
