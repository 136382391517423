import {
  ACCESS_TOKEN_SESSION_KEY,
  ID_TOKEN_SESSION_KEY,
  REFRESH_TOKEN_SESSION_KEY,
  COGNITO_CLIENT_ID,
  LOGIN_URL,
} from "../const";

export type TokenData = {
  id_token: string;
  access_token: string;
};

export const checkLoginStatus = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    sessionStorage.getItem(REFRESH_TOKEN_SESSION_KEY) !== null ||
    urlParams.has("code")
  );
};

export const fetchTokensWithCode = async (code: string) => {
  const result = await fetch("https://login.bourland.link/oauth2/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "authorization_code",
      client_id: COGNITO_CLIENT_ID,
      code: code,
      redirect_uri: window.location.origin.toString(),
    }).toString(),
  });
  if (result.ok) {
    const resultData = await result.json();
    sessionStorage.setItem(REFRESH_TOKEN_SESSION_KEY, resultData.refresh_token);
    sessionStorage.setItem(ACCESS_TOKEN_SESSION_KEY, resultData.access_token);
    sessionStorage.setItem(ID_TOKEN_SESSION_KEY, resultData.id_token);
    window.history.replaceState({}, document.title, "/");
    return resultData as TokenData;
  } else {
    window.location.assign(LOGIN_URL);
  }
  return { id_token: "", access_token: "" } as TokenData;
};

const parseJwt = (jwt: string) => {
  const jwtBody = jwt.split(".")[1];
  return JSON.parse(window.atob(jwtBody));
};

export const refreshTokens = async () => {
  let currRefreshToken = sessionStorage.getItem(REFRESH_TOKEN_SESSION_KEY);
  const urlParams = new URLSearchParams(window.location.search);
  if (currRefreshToken != null) {
    const result = await fetch("https://login.bourland.link/oauth2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "refresh_token",
        client_id: COGNITO_CLIENT_ID,
        refresh_token: currRefreshToken,
        redirect_uri: window.location.origin.toString(),
      }).toString(),
    });
    if (result.ok) {
      const resultData = await result.json();
      sessionStorage.setItem(
        REFRESH_TOKEN_SESSION_KEY,
        resultData.refresh_token
      );
      sessionStorage.setItem(ACCESS_TOKEN_SESSION_KEY, resultData.access_token);
      sessionStorage.setItem(ID_TOKEN_SESSION_KEY, resultData.id_token);
      return resultData as TokenData;
    }
  } else if (urlParams.has("code")) {
    return await fetchTokensWithCode(urlParams.get("code") as string);
  }
  window.location.assign(LOGIN_URL);
  return { id_token: "", access_token: "" } as TokenData;
};

export const getAccessToken = async () => {
  let currAccessToken = sessionStorage.getItem(ACCESS_TOKEN_SESSION_KEY);
  if (currAccessToken !== null) {
    const claims = parseJwt(currAccessToken);
    const nowSecs = Date.now() / 1000;
    if (claims.exp < nowSecs) {
      currAccessToken = await (await refreshTokens()).access_token;
    }
  } else {
    currAccessToken = await (await refreshTokens()).access_token;
  }
  return currAccessToken;
};

export const getIdToken = async () => {
  let currIdToken = sessionStorage.getItem(ID_TOKEN_SESSION_KEY);
  if (currIdToken !== null) {
    const claims = parseJwt(currIdToken);
    const nowSecs = Date.now() / 1000;
    if (claims.exp < nowSecs) {
      currIdToken = await (await refreshTokens()).id_token;
    }
  } else {
    currIdToken = await (await refreshTokens()).id_token;
  }
  return currIdToken;
};

export const getName = async () => {
  const token = await getIdToken();
  return parseJwt(token)["cognito:username"] as string;
};

export const logout = () => {
  sessionStorage.removeItem(ID_TOKEN_SESSION_KEY);
  sessionStorage.removeItem(ACCESS_TOKEN_SESSION_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_SESSION_KEY);
};

export default {
  checkLoginStatus,
  fetchTokensWithCode,
  getAccessToken,
  getName,
  logout,
};
