import { getAccessToken } from "./authService";
import { FileIndex } from "../types";
export type HelloResp = {
  message: string;
};
export const sayHello = async () => {
  const result = await fetch("/api/files/hello?name=adam", {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return (await result.json()) as HelloResp;
};

export const listFiles = async () => {
  const result = await fetch("/api/files", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  const data = await result.json();
  return data.files as FileIndex[];
};

export const getFileUrl = async (fileName: string) => {
  const result = await fetch(`/api/files/${fileName}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return await result.text();
};
export const getFile = async (fileName: string) => {
  const signedUrl = await getFileUrl(fileName);
  const fileRequest = await fetch(signedUrl, {
    mode: "cors",
  });
  return await fileRequest.text();
};

export const getUploadUrl = async (file: File) => {
  const result = await fetch("/api/files", {
    method: "POST",
    body: JSON.stringify({ file_name: file.name }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return await result.text();
};

export const uploadFile = async (file: File, uploadUrl: string) => {
  const uploadResult = await fetch(
    new Request(uploadUrl, {
      method: "PUT",
      body: file,
      headers: new Headers({ "Content-Type": "multipart/*" }),
    })
  );
  if (uploadResult.ok) {
    return "Success";
  } else {
    return await uploadResult.text();
  }
};

export const renameFile = async (oldName: string, newName: string) => {
  const result = await fetch(`/api/files/${oldName}`, {
    method: "PUT",
    body: JSON.stringify({ new_file_name: newName }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return await result.text();
};

export const deleteFile = async (name: string) => {
  const result = await fetch(`/api/files/${name}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
  return await result.text();
};

const filesService = {
  sayHello,
  listFiles,
  getFileUrl,
  getFile,
  getUploadUrl,
  uploadFile,
  renameFile,
  deleteFile,
};
export default filesService;
