import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { authService } from "../services";

function NavBar() {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>();
  useEffect(() => {
    async function getName() {
      setUserName(await authService.getName());
    }
    const params = window.location.search;
    if (params.includes("code") || authService.checkLoginStatus()) {
      setLoggedIn(true);
      getName();
    }
  }, []);
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <img src="bourland.ico" width="30" height="30" /> Bourland.link
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          {loggedIn ? <Nav.Link href="/files">Files</Nav.Link> : <></>}
        </Nav>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            {loggedIn ? (
              <Button
                variant="link"
                onClick={() => {
                  authService.logout();
                  setLoggedIn(false);
                  setUserName("");
                }}
              >
                Signed in as: {userName}
              </Button>
            ) : (
              <Button
                variant="link"
                onClick={async () => {
                  let name = await authService.getName();
                  setLoggedIn(true);
                  setUserName(name);
                }}
              >
                Log In
              </Button>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default NavBar;
